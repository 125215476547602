<template>
  <v-container :class="'grey lighten-3'">
    <v-row class="mh-main-row">
      <v-col cols="12" class="mh-main-col pt-0 pb-0 mb-0">
        <v-card outlined class="pl-2 pr-2">
          <v-card-title
            class="text-lg-h4 mh-page-title text-md-h5 pb-0 pb-md-4 d-flex justify-center justify-md-space-between align-baseline"
          >
          <span class="mr-4">Справка</span>
          <span class="designMsg">
            Разработано {{ appName }}. {{ currentYear }} v{{ majorVersion }}.{{
              frontVersion
            }}
          </span>            
          </v-card-title>
          <v-card-text class="infoText">
            <v-row>
              <v-col class="col-12 col-md-6">
                <v-card-title class="text-lg-h5 text-md-h6 pl-0 pb-0">Контакты техподдержки</v-card-title>
                <p class="mt-4 mb-4">
                  <a href="mailto:krz@mediahills.ru"><v-icon color="primary" class="mr-2" small>mdi-email</v-icon></a> <a href="mailto:krz@mediahills.ru" class="mailRef">krz@mediahills.ru</a>
                </p>
                <p>
                  Если у вас возникли проблемы при работе с личным кабинетом, пожалуйста, напишите на почту техподдержки.<br>
                  По возможности предоставьте всю информацию которая, относится к проблеме.<br>
                  Например:<br>
                  <ul>
                    <li>Скриншоты или фото экрана ЛК в хорошем качестве (желательно уместить весь экран в изображение)</li>
                    <li>Опишите, чего вы хотели добиться, и что выдал личный кабинет на ваш запрос (в чем конкретно проблема)</li>
                  </ul>
                  Все это поможет нам тщательней разобраться в вашей проблеме и быстрее ее решить.
                </p>  
              </v-col>
              <v-col class="col-12 col-md-6">
                <v-card-title class="text-lg-h5 text-md-h6 pl-0 pb-0">О разработчике и ПО</v-card-title>
                <div class="d-flex mt-4">
                  <div style="width:149px">
                    <v-img
                      class=""
                      height="102"
                      width="149"
                      :src="require('@/assets/login_logo.svg')"
                    />
                  </div>
                  <div class="ml-4">
                    <div>
                      <a href="mailto:info@mediahills.ru"><v-icon small color="primary" class="mr-2">mdi-email</v-icon></a> <a href="mailto:info@mediahills.ru" class="mailRef">info@mediahills.ru</a>
                    </div>
                    <div class="mt-2">
                      <a href="tel:+74997052933"><v-icon small color="primary" class="mr-2">mdi-phone</v-icon></a> 8 (499) 705-29-33
                    </div>
                    <div class="mt-2">
                      <a href="https://mediahills.ru/about#props" target="_blank"><v-icon small color="primary" class="mr-2">mdi-book-open-variant</v-icon></a> 
                      <a href="https://mediahills.ru/about#props" target="_blank" class="primary--text">Реквизиты юр. лица</a>
                    </div>
                  </div>
                </div>

                <div class="mt-4 mb-4">
                  <span class="chartInfoMessage" data-html2canvas-ignore>
                    <v-icon small color="#FFA91B" class="pr-1 align-baseline" style="margin-top: 2px;"
                      >mdi-lightbulb</v-icon
                    >
                    <span>АО «МЕДИА ХИЛС» с 15 марта 2019 года состоит в реестре аккредитованных Министерством цифрового развития, связи и массовых коммуникаций Российской Федерации организаций, осуществляющих деятельность в области информационных технологий (с номером записи в реестре 9916). Компания является российским разработчиком <a href="https://www1.fips.ru/registers-doc-view/fips_servlet?DB=EVM&DocNumber=2022616641&TypeFile=html" target="_blank">ПО «ПРОМЕТЕЙ»</a> и <a href="https://www1.fips.ru/registers-doc-view/fips_servlet?DB=EVM&DocNumber=2022616638&TypeFile=html" target="_blank">БАЗЫ ДАННЫХ ПО «ПРОМЕТЕЙ»</a>.
                    </span>  
                  </span>
                </div>  

              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <script
      defer
      type="application/javascript"
      :src="
        'https://js.onef.pro/static/reg1f_v1.js?1f_pixel_id=bb38ba5b-71c5-463f-b819-bf5757feaad3&event_type=visit&product=Help&order_id=' +
        check_cookie_name('_ym_uid')
      "
    ></script>
  </v-container>
</template>
<style scoped>
.infoText p, .infoText div, .infoText div span a {
  color: rgba(0,0,0,.87)!important;
}
.designMsg {
  color: rgba(0,0,0,.54);
  font-size: 16px;
  line-height: 24px;
}
.mailRef {
  color: rgba(0,0,0,.87)!important;
  text-decoration: none;
}
.mailRef:hover {
  color: rgba(0,0,0,.54);
  text-decoration: underline;
}
</style>
<script>
import moment from "moment";
import { CONSTS } from "../services/constants.js";
export default {

  name: "Help",
  data: () => ({
  }),
  mounted() {
    this.$router.push({ name: "help" });
  },
  computed: {
    isMobile() {
      return !this.$vuetify.breakpoint.mdAndUp;
    },
    appName() {
      return process.env.VUE_APP_APP_NAME;
    },
    majorVersion() {
      return CONSTS.MAJOR_VERSION;
    },
    currentYear() {
      return moment().format("YYYY");
    },
    frontVersion() {
      return process.env.VUE_APP_FRONT_VER;
    },            
  },
};
</script>
